import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { Injectable, NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MAT_DATE_LOCALE, MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { JwtModule } from "@auth0/angular-jwt";
import { MatDatepickerModule } from "@matheo/datepicker";
import { MatNativeDateModule } from "@matheo/datepicker/core";
import { EffectsModule } from "@ngrx/effects";
import {
  FullRouterStateSerializer,
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from "ngx-cookieconsent";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AppComponent } from "./app.component";
import { appRouter } from "./app.router";
import { AboutComponent } from "./components/core/about/about.component";
import { CarouselComponent } from "./components/core/carousel/carousel.component";
import { CartListComponent } from "./components/core/cart/cart-list/cart-list.component";
import { CartComponent } from "./components/core/cart/cart.component";
import { DiscountDetailsComponent } from "./components/core/discounts/discount-details/discount-details.component";
import { DiscountsComponent } from "./components/core/discounts/discounts.component";
import { FooterComponent } from "./components/core/footer/footer.component";
import { LogoToolbarComponent } from "./components/core/header/header.component";
import { HomeComponent } from "./components/core/home/home.component";
import { InfoFeaturesComponent } from "./components/core/info-features/info-features.component";
import { MainComponent } from "./components/core/main/main.component";
import { MobileNavigationComponent } from "./components/core/mobile-navigation/mobile-navigation.component";
import { OrderFormComponent } from "./components/core/order/order-form/order-form.component";
import { OrderInfoComponent } from "./components/core/order/order-info/order-info.component";
import { PrivacyComponent } from "./components/core/privacy/privacy.component";
import { ProductDetailsComponent } from "./components/core/product-details/product-details.component";
import { ProductComponent } from "./components/core/product/product.component";
import { ProductsCategoryComponent } from "./components/core/products-category/products-category.component";
import { ProductListComponent } from "./components/core/products-list/products-list.component";
import { RollsOfDayComponent } from "./components/core/rolls-of-day/rolls-of-day.component";
import { SidenavElementComponent } from "./components/core/sidenav-menu/sidenav-element/sidenav-element.component";
import { SidenavMenuComponent } from "./components/core/sidenav-menu/sidenav-menu.component";
import { SortProductsComponent } from "./components/core/sort-products/sort-products.component";
import { AddProductToCartComponent } from "./components/shared/add-product-to-cart-button/add-product-to-cart-button.component";
import { CallButtonComponent } from "./components/shared/call-button/call-button.component";
import { LikeButtonComponent } from "./components/shared/like-button/like-button.component";
import { OfflineMessageComponent } from "./components/shared/offline-message/offline-message.component";
import { ImageFallbackDirective } from "./directives/image-fallback.directive";
import { JwtHttpInterceptor } from "./jwt-interceptor";
import { CustomSerializer, reducers } from "./router/store/reducers";
import {
  ApiService,
  LocalStorageService,
  ProductsService,
  ThemeService,
  UtilsService,
} from "./services";
import { productsEffects, productsReducers } from "./store";

export function tokenGetter() {
  try {
    return localStorage?.getItem("access_token");
  } catch (error) {
    return;
  }
}
@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    pinch: { enable: false },
    rotate: { enable: false },
  };
}

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: "origami11.ru", // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  position: "bottom-right",
  palette: {
    popup: {
      background: "#d32f2f",
      text: "#fff",
    },
    button: {
      background: "#d32f2f",
      text: "#fff",
      border: "#fff",
    },
  },
  content: {
    dismiss: "",
    link: "",
    deny: "",
    policy: "Про cookies",
    message:
      "Наш сайт использует файлы cookies и другие похожие технологии, чтобы предоставлять услуги, наиболее отвечающие вашим потребностям, а также накапливать статистическую информацию для анализа и улучшения наших услуг и работы сайта. Продолжая использовать наш сайт, вы соглашаетесь с условиями использования файлов cookies",
    allow: "Принять",
  },
  theme: "classic",
  type: "opt-out",
};

@NgModule({
  declarations: [
    AppComponent,
    OrderInfoComponent,
    ProductListComponent,
    MainComponent,
    AboutComponent,
    HomeComponent,
    DiscountsComponent,
    ProductsCategoryComponent,
    SidenavMenuComponent,
    ProductListComponent,
    ProductsCategoryComponent,
    ProductComponent,
    SidenavMenuComponent,
    CartComponent,
    CartListComponent,
    OrderFormComponent,
    SidenavElementComponent,
    CarouselComponent,
    ProductDetailsComponent,
    AddProductToCartComponent,
    LogoToolbarComponent,
    AboutComponent,
    MainComponent,
    FooterComponent,
    OrderInfoComponent,
    HomeComponent,
    DiscountsComponent,
    DiscountDetailsComponent,
    MobileNavigationComponent,
    InfoFeaturesComponent,
    RollsOfDayComponent,
    SortProductsComponent,
    CallButtonComponent,
    OfflineMessageComponent,
    ImageFallbackDirective,
    LikeButtonComponent,
    PrivacyComponent,
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatNativeDateModule,
    MatDatepickerModule,
    BrowserModule.withServerTransition({
      appId: "ng-universal",
    }),
    appRouter,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatListModule,
    MatSelectModule,
    MatDialogModule,
    MatTabsModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSidenavModule,
    MatRippleModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatCardModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    InfiniteScrollModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([]),
    StoreModule.forFeature("shop", productsReducers),
    EffectsModule.forFeature(productsEffects),
    StoreDevtoolsModule.instrument({ maxAge: 20 }),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    MatIconModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: false,
    }),
  ],
  providers: [
    ApiService,
    LocalStorageService,
    UtilsService,
    ThemeService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtHttpInterceptor, multi: true },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    ProductsService,
    { provide: MAT_DATE_LOCALE, useValue: "ru-RU" },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    OrderFormComponent,
    ProductDetailsComponent,
    DiscountDetailsComponent,
  ],
})
export class AppModule {}
